import ProjectView from "@/Pages/Projects/View";
import ProjectsList from "@/Pages/Projects/List";
import Task from "@/Pages/Task";
// import ProjectsCreate from "@/Pages/Projects/Create";
// import Create from "@/Pages/Projects/Create";
import FlowView from "@/Pages/view";
import UserLogin from "@/Pages/User/Login";
import UserRegist from "@/Pages/User/Regist";
import UserInfo from "@/Pages/User/Info";
import { Navigate } from "react-router-dom";

export default [
  {
    path: "/",
    element: <Navigate to="/projects" />,
    showMenu: false,
  },

  {
    path: "/user",
    title: "用户",
    showMenu: false,
    children: [
      {
        path: "/user",
        element: <Navigate to="/user/login" />,
      },
      {
        path: "/user/login",
        title: "用户登录",
        element: <UserLogin />,
      },
      {
        path: "/user/regist",
        title: "用户注册",
        element: <UserRegist />,
      },
      {
        path: "/user/info",
        title: "用户信息",
        element: <UserInfo />,
      },
    ],
  },

  {
    path: "/projects",
    title: "项目",
    // showMenu: false,
    // element: <Home />,
    children:[
      {
        path: "/projects",
        element: <Navigate to="/projects/list" />,
        showMenu: false,
      },
      {
        path: "/projects/list",
        title:'项目列表',
        element: <ProjectsList />,
        showMenu: false,
      },
      {
        path: "/projects/view",
        title:'项目浏览',
        element: <ProjectView />,
        showMenu: false,
      },
    ]
  },
  {
    path: "/task",
    title: "任务",
    // showMenu: false,
    element: <Task />,
  },
  {
    path: "/flowview",
    title: "任务",
    showMenu: false,
    element: <FlowView />,
  },


  // {
  //   path: "/createCal?k=subCal",
  //   title: "催化剂取代基优化",
  //   element: <MetalloceneCreate />,
  // },
];

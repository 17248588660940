import React, { useState, useEffect } from "react";
import { userInfoField, webTitle } from "@/config/config";
import { LoginParamsType, login, getCurrentUser } from "@/services/common";
import { Md5 } from "md5-typescript";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Navigate } from "react-router-dom";
import { Button, Divider, Form, Input, Image, message } from "antd";
import logo from "@/assets/logomdp.svg";
import loginbg from "@/assets/imgs/loginbg.jpg";

const userINfo = sessionStorage.getItem(userInfoField);

const Component = (props: any) => {
  const onFinish = async (values: LoginParamsType) => {
      const loginRes = await login({
        username: values.username,
        password: values.password,
      });
      if(loginRes.status!==200){
        message.error("用户名或密码错误！");
        return;
      }
      const loginJson = await loginRes.json();
      const userRes = await getCurrentUser(loginJson.access_token);
      const userJson = await userRes.json();
      sessionStorage.setItem(
        userInfoField,
        JSON.stringify({
          ...loginJson,
          ...userJson,
        })
      );

      window.location.href = "/";
    
  };

  if (userINfo !== null) {
    return <Navigate to="/" />;
  }

  return (
    <div className="loginPage">
      <div className="loginLeft">
        <div className="loginTitle">{webTitle}</div>
        <div className="loginImg">
          <Image src={loginbg} preview={false} />
        </div>
      </div>
      <div className="loginRight">
        <div>
          <div className="logoPic">
            <Image src={logo} preview={false} />
          </div>
          <Divider
            style={{ margin: "3rem 0", borderColor: "rgba(0,0,0,0.3)" }}
          ></Divider>
          <Form
            name="basic"
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: "请输入用户名!" }]}
            >
              <Input
                size="large"
                placeholder="用户名"
                prefix={<UserOutlined />}
                allowClear
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: "请输入登录密码!" }]}
            >
              <Input.Password
                size="large"
                placeholder="登录密码"
                prefix={<LockOutlined />}
              />
            </Form.Item>

            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                登录
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Component;

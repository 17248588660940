import { Space } from 'antd';
import React, { useState, useEffect } from 'react';

const Component = (props: any) => {
  const { showParmsPanel, setShowParmsPanel } = props;
  return (
    <div className='p_ParmsPanel'>
      {/* <img src="/parmspanel.jpg" style={{height:'100%'}} alt="" 
  onClick={()=>setShowParmsPanel(false)}
  /> */}

      <div className='p_ParmsPanel_title'>
        <span>参数输入：</span>
        <div className='icon_close_small'
          onClick={() => setShowParmsPanel(false)}
        />
      </div>
      <div className="p_ParmsPanel_inner">
        <ul className='p_ParmsPanel_main_inner'>
          <li>
            <div className='p_ParmsPanel_ele_title'>
              <span>混合加热炉</span>
              <div className='icon_arrow_w right'></div>
            </div>
            <div className='p_ParmsPanel_ele_content'>
              <div className='formLine'>
                <div className='formEle_chebox'><label><input type="checkbox" checked /> <span>是否启用</span></label></div>
              </div>
              <div className='formLine mt1'>
                <div className='formLabel'>氧气入口温度：</div>
                <div className='formEle'><input type="text" value={127.96} /></div>
                <div className='formUnit'>C</div>
              </div>
              <div className='formLine'>
                <div className='formLabel'>氧气出口温度：</div>
                <div className='formEle'><input type="text" value={258.96} /></div>
                <div className='formUnit'>C</div>
              </div>
              <div className='formLine'>
                <div className='formLabel'>高压蒸汽：</div>
                <div className='formEle'><input type="text" value={58452.36} /></div>
                <div className='formUnit'>kmol/h</div>
              </div>
              <div className='formLine'>
                <div className='formLabel'>出口压力：</div>
                <div className='formEle'><input type="text" value={256.36} /></div>
                <div className='formUnit'>bar</div>
              </div>
              <div className='formLine mt2'>
                <div className='formEle_chebox'><label><input type="checkbox" checked /> <span>是否使用冷凝器规定</span></label> <div className='form_settingBtn'>设置规定...</div></div>
              </div>
              <div className='formSepLine' />
              <div className='formLine mt2'>
                <div className='formEle_chebox'><label><input type="checkbox" checked /> <span>更换元件：</span></label></div>
              </div>
              <ul className='form_EleList mt2'>
                <li><img src='/icon/boiler_1.svg' alt="" /></li>
                <li><img src='/icon/boiler_2.svg' alt="" /></li>
                {/* <li><img src='/icon/tower_1.svg' alt="" /></li> */}
                <li><img src='/icon/tower_2.svg' alt="" /></li>
                <li><img src='/icon/tower_3.svg' alt="" /></li>
              </ul>
            </div>
          </li>
          <li>
            <div className='p_ParmsPanel_ele_title'>
              <span>脱氢塔：</span>
              <div className='icon_arrow_w right'></div>
            </div>
            <div className='p_ParmsPanel_ele_content'>
              <div className='formLine'>
                <div className='formEle_chebox'><label><input type="checkbox" checked /> <span>是否启用</span></label></div>
              </div>
              <div className='formLine mt1'>
                <div className='formLabel'>总塔板数：</div>
                <div className='formEle'><input type="text" value={12} /></div>
                <div className='formUnit'></div>
              </div>
              <div className='formLine'>
                <div className='formLabel'>氧气出口温度：</div>
                <div className='formEle'><input type="text" value={258.96} /></div>
                <div className='formUnit'>C</div>
              </div>
              <div className='formLine'>
                <div className='formLabel'>循环蒸汽：</div>
                <div className='formEle'><input type="text" value={25452.78} /></div>
                <div className='formUnit'>kmol/h</div>
              </div>
              <div className='formLine'>
                <div className='formLabel'>出口压力：</div>
                <div className='formEle'><input type="text" value={256.36} /></div>
                <div className='formUnit'>bar</div>
              </div>
            </div>
          </li>
          <li>
            <div className='p_ParmsPanel_ele_title'>
              <span>变换气分离器：</span>
              <div className='icon_arrow_w right'></div>
            </div>
            <div className='p_ParmsPanel_ele_content'>
              <div className='formLine'>
                <div className='formEle_chebox'><label><input type="checkbox" checked /> <span>是否启用</span></label></div>
              </div>
              <div className='formLine mt1'>
                <div className='formLabel'>氧气入口温度：</div>
                <div className='formEle'><input type="text" value={127.96} /></div>
                <div className='formUnit'>C</div>
              </div>
              <div className='formLine'>
                <div className='formLabel'>氧气出口温度：</div>
                <div className='formEle'><input type="text" value={258.96} /></div>
                <div className='formUnit'>C</div>
              </div>
              <div className='formLine'>
                <div className='formLabel'>高压蒸汽：</div>
                <div className='formEle'><input type="text" value={58452.36} /></div>
                <div className='formUnit'>kmol/h</div>
              </div>
              <div className='formLine'>
                <div className='formLabel'>出口压力：</div>
                <div className='formEle'><input type="text" value={256.36} /></div>
                <div className='formUnit'>bar</div>
              </div>
              <div className='formLine'>
                <div className='formLabel'>操作压力：</div>
                <div className='formEle'><input type="text" value={142.25} /></div>
                <div className='formUnit'>bar</div>
              </div>
            </div>
          </li>
        </ul>
        <div className='p_ParmsPanel_submitArea'>
          <div className='formLine'>
            <div className='formEle_chebox'><label><input type="checkbox" checked /> <span>添加到任务</span></label> <Space><div className="button edit">提交计算</div><div className="button cancle">重置</div></Space></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Component;
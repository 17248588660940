import { useRoutes } from 'react-router-dom';
import Navs from '../../config/routes';

const App = () => {
  return useRoutes(Navs)
  // return (
  //   <Routes>
  //   {Navs.map((item: any) => {
  //     return <Route 
  //     element={item.component} 
  //     key={item.path} 
  //     path={item.path}
  //     />;
  //   })}
  // </Routes>
  // );
};

export default App;

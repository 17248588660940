import React, { useState, useEffect } from "react";
import { simpleSource2 } from "@/config/const";
import SigleProject from './SingleProject'
import {getQueryString} from '@/config/func'

const Component = (props: any) => {
  const { showLeft, setShowLeft } = props;
  const [source,setSource]=useState<any>(null);
  const [curSource,setCurSource]=useState<any>(null);
  const [curFlowID,setCurFlowID]=useState<any>(null);
  console.log();

  useEffect(()=>{
    const c:any=getQueryString('k')?simpleSource2.find((item:any)=>item.id===getQueryString('k')):simpleSource2[0];
    const fid:any=getQueryString('f')?getQueryString('f'):'1'
    setSource(simpleSource2)
    setCurSource(c)
    setCurFlowID(fid)
  },[getQueryString('k'),getQueryString('f')])
  return (
    <>
      {showLeft ? (
        <div className="mc_left">
          <div className="left_inner">
            <ul className="plistArea">
              {source?
                source.map((item:any,index:any)=>{
                  return <SigleProject source={item} curFlowID={curFlowID} setCurFlowID={setCurFlowID} setCurSource={setCurSource} curSource={curSource} key={index} />
                })
              :''}
            </ul>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Component;

import { Popconfirm } from 'antd';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Component = (props: any) => {
  const { source,del_confirm } = props;
  const history = useNavigate();

  return (
    <ul className="indexShow_content">
      {source.map((item: any, index: any) => {
        return (
          <li key={index}>
            <div className="showProjects">
              <div className="showPimg" style={{ cursor: 'pointer' }}
                onClick={() => history(`/projects/view?k=${item.id}`)}
              >
                <div className="simg">
                  <img src={item.pic} alt="" />
                </div>
              </div>
              <div className="showPname">
                <div className="sp_left">
                  <div className="sp_name"
                    onClick={() => history(`/projects/view?k=${item.id}`)}
                  >{item.name}</div>
                  <div className="sp_date">{dayjs(item.date).format("YYYY-MM-DD")}</div>
                </div>
                <div className="sp_right">
                  <div className="sp_icon sp_icon_view" title="查看"
                    onClick={() => history(`/projects/view?k=${item.id}`)}
                  />
                  <div className="sp_icon sp_icon_edit" title="修改"
                    // onClick={() => history(`/create?k=${item.id}`)} 
                  />
                  <Popconfirm
                    title="确定删除该项目？"
                    description="删除后不可恢复！"
                    onConfirm={del_confirm}
                    // onCancel={cancel}
                    okText="是"
                    cancelText="否"
                  >
                    <div className="sp_icon sp_icon_delete" title="删除" />
                  </Popconfirm>
                </div>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};


export default Component;
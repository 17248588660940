import { Popconfirm, Space } from 'antd';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Component = (props: any) => {
  const { source, del_confirm } = props;
  const history = useNavigate();
  return (
    <div className='indexTable mt2'>
      <table>
        <thead>
          <tr>
            <th>项目名称</th>
            <th>项目描述</th>
            <th>流程总数</th>
            <th>计算任务</th>
            <th>创建日期</th>
            <th>创建人</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          {source.map((item: any, index: any) => {
            return <tr key={index}>
              <td className='tabelImg'
              onClick={() => history(`/projects/view?k=${item.id}`)}
              >
                <div>
                  <div className='itemPic'><div className='itemPicInner'><img src={item.pic} alt="" /></div></div>
                </div>
                <div className='itemName'>{item.name}</div>
              </td>
              <td className='itemDesc'>{item.desc}</td>
              <td className='itemFlowTotal'>4</td>
              <td className='itemTaskTotal'>8</td>
              <td>{dayjs(item.date).format('YYYY-MM-DD')}</td>
              <td>张XX</td>
              <td className='item_action_td'>
                <div className='item_action_c'>
                  <div className="item_action">
                    <div className="sp_icon sp_icon_view" title="查看"
                      onClick={() => history(`/projects/view?k=${item.id}`)}
                    />
                    <div className="sp_icon sp_icon_edit" title="修改"
                      // onClick={() => history(`/create?k=${item.id}`)} 
                    />
                    <Popconfirm
                      title="确定删除该项目？"
                      description="删除后不可恢复！"
                      onConfirm={del_confirm}
                      // onCancel={cancel}
                      okText="是"
                      cancelText="否"
                    >
                      <div className="sp_icon sp_icon_delete" title="删除" />
                    </Popconfirm>
                  </div>
                </div>

              </td>
            </tr>
          })}
        </tbody>
      </table>

    </div>
  );
};


export default Component;
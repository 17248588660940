import React, { memo, CSSProperties, useCallback } from "react";
import {
  Handle,
  Position,
  NodeProps,
  Connection,
  Edge,
  useOnViewportChange,
  Viewport,
  Background,
} from "@xyflow/react";

import type { Reactor3 } from ".";

const targetHandleStyle: CSSProperties = { background: "#555", left: 50 };

const onConnect = (params: Connection | Edge) =>
  console.log("handle onConnect", params);

function Reactor3({ data, isConnectable }: NodeProps<Reactor3>) {
  const onStart = useCallback(
    (viewport: Viewport) => console.log("onStart", viewport),
    []
  );
  const onChange = useCallback(
    (viewport: Viewport) => console.log("onChange", viewport),
    []
  );
  const onEnd = useCallback(
    (viewport: Viewport) => console.log("onEnd", viewport),
    []
  );

  useOnViewportChange({
    onStart,
    onChange,
    onEnd,
  });

  return (
    <div
      style={{
        width: "280px",
        height: "250px",
        backgroundImage: "url(/reactor3.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        // backgroundColor: "transparent",
      }}
    >
      <div style={{ position: "absolute", right: 0}}>
        <strong>反应器 3</strong>
      </div>
      <Handle
        type="target"
        position={Position.Top}
        style={targetHandleStyle}
        onConnect={onConnect}
      />
    </div>
  );
}

export default memo(Reactor3);

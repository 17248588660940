const { REACT_APP_ENV } = process.env;


// 网站程序名称
export const webTitle='工业计算平台';

// 应用权限登录名称
export const appLoginName='工业计算平台';

const preFix='ICP';//常数字段前缀

// 主题缓存字段名
export const themsField=`${preFix}_theme`;

// 用户信息缓存字段
export const userInfoField=`${preFix}_userInfo`;

export const themes=[
  {
    name:'浅色',
    code:'light'
  },
  {
    name:'暗色',
    code:'dark'
  },
]
import { DragEvent,useState,useEffect } from 'react';
import {elementsLibs} from '@/config/const'
import {CloseOutlined} from '@ant-design/icons'

const onDragStart = (event: DragEvent, nodeType: any) => {
  event.dataTransfer.setData('application/reactflow', nodeType);
  event.dataTransfer.effectAllowed = 'move';
};

const Sidebar = (props:any) => {
  const {
    setShowElementsBar,
    showElementsBar,
    curmode,
    setCurmode
  }=props;
  const [curCate,setCurCate]=useState<any>(null);
  useEffect(()=>{
    setCurCate(elementsLibs[0])
  },[])

  const closePanel=()=>{
    setShowElementsBar(false);
    if(curmode==='mod3'){
      setCurmode('mod_left')
    }else if(curmode==='mod_bottom'){
      setCurmode('mod_full')
    }
  }

  return (
    <div className='p_elementsbar'>
      <div className='p_elementsbar_top'>
      <ul className='p_ele_cate'>
        {
        curCate?
        elementsLibs.map((item:any,index:any)=>{
          return <li 
          key={index} 
          className={`${curCate.type===item.type?'active':''}`}
          onClick={()=>setCurCate(item)}
          >
            {item.type}
          </li>
        }):''}
      </ul>
      <div className='p_ele_close' onClick={closePanel}><CloseOutlined /></div>
      </div>
      
      <ul className='p_cate_children'>
      {
      curCate?
      curCate.children.map((item:any,index:any)=>{
        return (
          <li key={index} 
          onDragStart={(event: DragEvent) => onDragStart(event, 
            JSON.stringify({img:item.icon,type:item.name})
          )} 
          draggable
          >
            <img src={item.icon} alt="" />
          </li>
        )
      })
      :''
      }
      </ul>
      
    </div>
  );
};

export default Sidebar;

const Component = (props: any) => {
  const { title, extra } = props;
  return (
    <div className="pageTitle">
      <div className="t1" style={{ color: 'aqua' }}>{title}</div>
      {extra ?
        <div className="pageTopExtra">
          {extra}
        </div>
        : ''}
    </div>
  );
};


export default Component;
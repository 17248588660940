import React, { memo, CSSProperties, useCallback } from "react";
import {
  Handle,
  Position,
  NodeProps,
  Connection,
  Edge,
  useOnViewportChange,
  Viewport,
  Background,
} from "@xyflow/react";

import type { Reactor1 } from ".";

const targetHandleStyle: CSSProperties = { background: "#555" };
const sourceHandleStyleA: CSSProperties = { ...targetHandleStyle, top: 90 };
const sourceHandleStyleB: CSSProperties = {
  ...targetHandleStyle,
  left: 260,
  // top: "auto",
};

const onConnect = (params: Connection | Edge) =>
  console.log("handle onConnect", params);

function Reactor1({
  data,
  isConnectable,
}: NodeProps<Reactor1>) {
  const onStart = useCallback(
    (viewport: Viewport) => console.log("onStart", viewport),
    []
  );
  const onChange = useCallback(
    (viewport: Viewport) => console.log("onChange", viewport),
    []
  );
  const onEnd = useCallback(
    (viewport: Viewport) => console.log("onEnd", viewport),
    []
  );

  useOnViewportChange({
    onStart,
    onChange,
    onEnd,
  });

  return (
    <div
      style={{
        width: "280px",
        height: "300px",
        backgroundImage: "url(/reactor1.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundColor: "transparent",
      }}
    >
      <div>
        <strong>反应器 1</strong>
      </div>
      {/* <Handle
        type="target"
        position={Position.Top}
        style={targetHandleStyle}
        onConnect={onConnect}
      />
      <Handle
        type="source"
        position={Position.Right}
        id="a"
        style={sourceHandleStyleA}
        isConnectable={isConnectable}
        onMouseDown={(e) => {
          console.log("You trigger mousedown event", e);
        }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        style={sourceHandleStyleB}
        isConnectable={isConnectable}
      /> */}
    </div>
  );
}

export default memo(Reactor1);

import React, { useState, useEffect } from 'react';

const Component = (props: any) => {
  const {source,curSource,setCurSource,curFlowID,setCurFlowID}=props;
return (
<li className={`${curSource.id===source.id?'active':''}`} onClick={()=>setCurSource(source)}>
  <div className='pname'>{source.name}<div className='ext'></div></div>
  <div className='showFlowList'>
    {['一','二','三','四','五','六','七','八'].map((item:any,index:any)=>{
      return (
        <div 
        className={`showsFlow${curFlowID===`${index+1}`?' active':''}`} 
        key={index}
        onClick={()=>setCurFlowID(`${index+1}`)}
        ><input type="checkbox" /><span>流程{item}</span></div>
      )
    })}
  </div>
</li>
);
};

export default Component;
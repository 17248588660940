import ReactDOM from 'react-dom/client';
import './less/main.less';
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import UserLogin from '@/Pages/User/Login';
import {userInfoField,webTitle} from '@/config/config';
import Helmet from 'react-helmet';

const userInfo=sessionStorage.getItem(userInfoField);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <BrowserRouter>
    <Helmet>
      <title>{webTitle}</title>
    </Helmet>

    {userInfo===null?<UserLogin />:<App />}
  </BrowserRouter>
);


import React, { useState, useEffect } from "react";
import "./index.css";
import App from "./FlowEditor";
import "./index.less";
import ParmsPanel from "./ParmsPanel";
import {
ReactFlowProvider,
} from '@xyflow/react';

const Component = (props: any) => {
  const {showElementsBar}=props;
  const [showParmsPanel, setShowParmsPanel] = useState(true);
  return (
    <div className='p_Editor'
    style={{height: `${showElementsBar?'calc(100% - 17rem)':'calc(100% - 4rem)'}`}}
    >
      <div className={`p_editorMain${showParmsPanel?'':' hides'}`}>
      <ReactFlowProvider>
      <App setShowParmsPanel={setShowParmsPanel} />
      </ReactFlowProvider>
      </div>
      {
        showParmsPanel?<ParmsPanel setShowParmsPanel={setShowParmsPanel} />:''
      }
    </div>
  );
};

export default Component;

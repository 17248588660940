import React, { useState, useEffect } from 'react';

const Component = (props: any) => {
  const {
    setShowElementsBar,
    showElementsBar,
    setShowLeft,
    showLeft,
    curmode, 
    setCurmode
  } = props;

  const [curP,setCurP]=useState('1');

  const modes: any = [
    'mod3',
    'mod_left',
    'mod_bottom',
    'mod_full'
  ]

  const setShowMode=(item:any)=>{
    setCurmode(item);
    if(item==='mod3'){
      setShowElementsBar(true)
      setShowLeft(true)
    }else if(item==='mod_left'){
      setShowElementsBar(false)
      setShowLeft(true)
    }else if(item==='mod_bottom'){
      setShowElementsBar(true)
      setShowLeft(false)
    }else{
      setShowElementsBar(false)
      setShowLeft(false)
    }
  }
  return (
    <div className='p_topToolBar'>
      {/* <img src='/topbar.jpg' alt="" /> */}
      <ul className='p_tools'>
        <li>
          <div className='icon newFlow' />
          <div className='p_tools_text'>新建</div>
        </li>
        <li>
          <div className='icon save' />
          <div className='p_tools_text'>保存</div>
        </li>
        <li>
          <div className='icon import' />
          <div className='p_tools_text'>导入流程</div>
        </li>
        <li>
          {/* <div className='icon import' /> */}
          <div className='p_tools_text'>切换输入参数：</div>
          <ul className='p_tools_changeParams'>
            {['1','2','3','4','5'].map((item:any)=>{
              return (
                <li key={item} className={curP===item?'active':''}
                onClick={()=>setCurP(item)}
                >{item}</li>
              )
            })}
          </ul>
        </li>
      </ul>
      <ul className='p_tools_pageMod'>
        {modes.map((item: any) => {
          return <li 
          className={curmode === item ? 'active' : ''}
          key={item}
          ><div className={`icon ${item}`} onClick={() => {
            setShowMode(item)
          }} /></li>
        })}
      </ul>
    </div>
  );
};

export default Component;
import React, { useState, useEffect } from "react";
import Left from './Left';
import Right from './Right'

const Component = (props: any) => {
  const [showLeft, setShowLeft] = useState(true);
  const [curmode, setCurmode] = useState<any>('mod3')
  const [showElementsBar, setShowElementsBar] = useState(true);
  return (
    <div className="mainContent">
      <Left
        showLeft={showLeft}
        setShowLeft={setShowLeft}
        showElementsBar={showElementsBar}
        setShowElementsBar={setShowElementsBar}
        curmode={curmode}
        setCurmode={setCurmode} />
      <Right
        showLeft={showLeft}
        setShowLeft={setShowLeft}
        showElementsBar={showElementsBar}
        setShowElementsBar={setShowElementsBar}
        curmode={curmode}
        setCurmode={setCurmode}
      />
    </div>
  );
};

export default Component;

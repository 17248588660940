import {
  useState,
  useEffect,
  MouseEvent,
  ChangeEvent,
  useCallback,
  useRef,
  DragEvent,
} from 'react';
import {
  ReactFlow,
  MiniMap,
  Controls,
  addEdge,
  Node,
  Edge,
  Position,
  SnapGrid,
  useEdgesState,
  Background,
  OnNodeDrag,
  OnInit,
  applyNodeChanges,
  OnNodesChange,
  OnConnect,
  OnBeforeDelete,
  BuiltInNode,
  BuiltInEdge,
  NodeTypes,
  ReactFlowProvider,
  useReactFlow,
  Panel,
  ReactFlowInstance,
  MarkerType,
} from '@xyflow/react';
import FloatingEdge from '../Components/FloatingEdge';
import MultiHandleNode from '../Components/MultiHandleNode';


import Reactor1 from './Reactor1';
import Reactor2 from './Reactor2';
import Reactor3 from './Reactor3';
import Reactor4 from './Reactor4';
import styles from './dnd.module.css';

export type MultiHandleNode = Node<
  { color: string; onChange: (event: ChangeEvent<HTMLInputElement>) => void },
  'multiHandleNode'
>;
export type Reactor1 = Node<
  { color: string; onChange: (event: ChangeEvent<HTMLInputElement>) => void },
  'reactorNode1'
>;
export type Reactor2 = Node<
  { color: string; onChange: (event: ChangeEvent<HTMLInputElement>) => void },
  'reactorNode2'
>;
export type Reactor3 = Node<
  { color: string; onChange: (event: ChangeEvent<HTMLInputElement>) => void },
  'reactorNode3'
>;
export type Reactor4 = Node<
  { color: string; onChange: (event: ChangeEvent<HTMLInputElement>) => void },
  'reactorNode4'
>;
export type MyNode =
  | BuiltInNode
  | Reactor1
  | Reactor2
  | Reactor3
  | Reactor4
  | MultiHandleNode
  | Node;
export type MyEdge = Edge;

const FlowEditor = (props:any) => {
  const {setShowParmsPanel}=props;
  const ref = useRef(null);

  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance>();

    const onNodeDragStop: OnNodeDrag<MyNode> = (_, node) =>
      console.log('drag stop', node);
    const onNodeClick = (_: MouseEvent, node: MyNode) => {
      setShowParmsPanel(true)
      console.log('click', node)
    };
    
    const initBgColor = 'transparent';
    
    const connectionLineStyle = { stroke: '#fff' };
    const snapGrid: SnapGrid = [16, 16];
    
    const nodeTypes: NodeTypes = {
      reactorNode1: Reactor1,
      reactorNode2: Reactor2,
      reactorNode3: Reactor3,
      reactorNode4: Reactor4,
      multiHandleNode: MultiHandleNode,
    };
    const edgeTypes = {
      floating: FloatingEdge,
    };
    const onDragOver = (event: DragEvent) => {
      event.preventDefault();
      event.dataTransfer.dropEffect = 'move';
    };
    let id = 0;
    const getId = () => `dndnode_${id++}`;

  const {
    addNodes,
    getNodes,
    getEdges,
    deleteElements,
    updateNodeData,
    toObject,
    setViewport,
  } = useReactFlow();

  const [nodes, setNodes] = useState<MyNode[]>([]);

  const onInit: OnInit<MyNode, MyEdge> = (reactFlowInstance) => {
    console.log('flow loaded:', reactFlowInstance);
    setReactFlowInstance(reactFlowInstance as ReactFlowInstance);
  };

  // const onInit = (rfi: ReactFlowInstance) => setReactFlowInstance(rfi);

  const onNodesChange: OnNodesChange<MyNode> = useCallback(
    (changes) =>
      setNodes((nds) => {
        const nextNodes = applyNodeChanges(changes, nds);
        return nextNodes;
      }),
    [setNodes]
  );

  const [edges, setEdges, onEdgesChange] = useEdgesState<MyEdge>([]);

  const [bgColor, setBgColor] = useState<string>(initBgColor);

  useEffect(() => {
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id !== '2' || node.type !== 'reactorNode1') {
            return node;
          }

          const color = event.target.value;

          setBgColor(color);

          return {
            ...node,
            data: {
              ...node.data,
              color,
            },
          };
        })
      );
    };

    setNodes([
      {
        id: '1',
        type: 'reactorNode4',
        data: { onChange: onChange, color: initBgColor, backgroundImage:'/reactor1.svg' },
        style: { border: '1px solid #777', padding: 10 },
        position: { x: 0, y: 0 },
      },
      {
        id: '2',
        type: 'multiHandleNode',
        // dragHandle: '.custom-drag-handle',
        data: { onChange: onChange, color: initBgColor },
        style: { width: "280px",
          height: "300px",
          backgroundImage: "url(/reactor1.svg)", 
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        },
        position: { x: 400, y: 250 },
      },
      {
        id: '3',
        type: 'reactorNode2',
        data: { onChange: onChange, color: initBgColor },
        style: { border: '1px solid #777', padding: 10 },
        position: { x: 850, y: 250 },
      },
      {
        id: '4',
        type: 'reactorNode3',
        data: { onChange: onChange, color: initBgColor },
        style: { border: '1px solid #777', padding: 10 },
        position: { x: 550, y: 800 },
      },
    ]);

    setEdges([
      // {
      //   id: 'e1-2',
      //   source: '1',
      //   type: 'floating',
      //   target: '2',
      //   animated: true,
      //   label: '气',
      //   style: { stroke: '#fff', strokeWidth: 5 },
      // },
      // {
      //   id: 'e2a-3',
      //   source: '2',
      //   sourceHandle: 'a',
      //   target: '3',
      //   type: 'floating',
      //   animated: true,
      //   label: '废液',
      //   style: { stroke: '#00f', strokeWidth: 20 },
      // },
      // {
      //   id: 'e2b-4',
      //   source: '2',
      //   type: 'floating',
      //   sourceHandle: 'b',
      //   target: '4',
      //   animated: true,
      //   label: '废气',
      //   style: { stroke: '#f00', strokeWidth: 5 },
      // },
    ]);
  }, []);
  useEffect(() => {
    setViewport({ x: 0, y: 0, zoom: 0.3 });
  }, []);
  const onConnect: OnConnect = useCallback(
    (connection) => {
      console.log(connection);
      setEdges((eds) =>
        addEdge(
          {
            ...connection,
            animated: true,
            style: { stroke: '#fff' },
            type: 'smoothstep',
          },
          eds
        )
      );
    },
    [setEdges]
  );

  const onBeforeDelete: OnBeforeDelete<MyNode, MyEdge> = useCallback(
    async (params) => true,
    []
  );
  const addNode = () => {
    addNodes({
      id: `${Math.random()}`,
      data: { label: '新反应器' },
      position: { x: Math.random() * 300, y: Math.random() * 300 },
      // className: 'light',
    });
  };

  const onDrop = (event: DragEvent) => {
    event.preventDefault();

    if (reactFlowInstance) {
      const type:any = event.dataTransfer.getData('application/reactflow');
      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const newNode: Node = {
        id: `${Math.random()}`,
        type,
        position,
        data: { label: `` },
        style:{
          backgroundImage:`url(${JSON.parse(type).img})`,
          width:'100px',
          height:'100px',
          backgroundRepeat:'no-repeat',
          backgroundColor:'transparent'
        }
        
      };

      setNodes((nds) => {
        nds = nds.concat(newNode);
        console.log(nds);
        return nds;
      });
    }
  };
  return (
    <div className={styles.dndflow}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onInit={onInit}
        onNodeClick={onNodeClick}
        onNodeDragStop={onNodeDragStop}
        snapToGrid={true}
        snapGrid={snapGrid}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        connectionLineStyle={connectionLineStyle}
        fitView
        minZoom={0.3}
        maxZoom={10}
        onBeforeDelete={onBeforeDelete}
        ref={ref}
        onDrop={onDrop}
        onDragOver={onDragOver}
      >
        <Panel position='top-right'>
          <button onClick={addNode}>addNode</button>
        </Panel>

        {/* <MiniMap<MyNode>
          nodeStrokeColor={(n: MyNode): string => {
            if (n.type === 'input') return '#0041d0';
            if (n.type === 'reactorNode1') return bgColor;
            if (n.type === 'reactorNode2') return bgColor;
            if (n.type === 'reactorNode3') return bgColor;
            if (n.type === 'reactorNode4') return bgColor;

            if (n.type === 'output') return '#ff0072';

            return '#eee';
          }}
          nodeColor={(n: MyNode): string => {
            if (n.type === 'reactorNode1') return bgColor;
            if (n.type === 'reactorNode2') return bgColor;
            if (n.type === 'reactorNode3') return bgColor;
            if (n.type === 'reactorNode4') return bgColor;
            return '#000';
          }}
        /> */}
        <Controls />
        <Background bgColor={bgColor} />
      </ReactFlow>
      {/* <Sidebar /> */}
    </div>
  );
};

export default FlowEditor;

// export default () => (
//   <ReactFlowProvider>
//     <FlowEditor  />
//   </ReactFlowProvider>
// );

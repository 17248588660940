import { replaceFormulaStr } from './func';
import i_1 from "@/assets/imgs/index/i_1.jpg";
import i_2 from "@/assets/imgs/index/i_2.jpg";
import i_3 from "@/assets/imgs/index/i_3.jpg";
import i_4 from "@/assets/imgs/index/i_4.jpg";
import i_5 from "@/assets/imgs/index/i_5.jpg";
import i_6 from "@/assets/imgs/index/i_6.jpg";

export const projectCategory: any = [
	'煤制烯烃',
	'合成氨',
	'煤制油',
	'精馏',
	'脱硫',
]


//相态
export const phases = {
	Liquid: '液相',
	Gas: '气相',
	Solid: '固相'
}

export const streamsParams: any = {
	temperature: {
		cn: '温度',
		en: 'Temperature',
		field: 'temperature',
		unit: 'C'
	},
	pressure: {
		cn: '压力',
		en: 'Pressure',
		field: 'pressure',
		unit: 'bar'
	},
	molar_enthalpy: {
		cn: '摩尔焓',
		en: 'Molar Enthalpy',
		field: 'molar_enthalpy',
		unit: 'cal/mol'
	},
	mass_enthalpy: {
		cn: '质量焓',
		en: 'Mass Enthalpy',
		field: 'mass_enthalpy',
		unit: 'cal/gm'
	},
	molar_entropy: {
		cn: '摩尔熵',
		en: 'Molar Entropy',
		field: 'molar_entropy',
		unit: 'cal/mol-K'
	},
	mass_entropy: {
		cn: '质量熵',
		en: 'Mass Entropy',
		field: 'mass_entropy',
		unit: 'cal/gm-K'
	},
	enthalpy_flow: {
		cn: '焓流量',
		en: 'Enthalpy Flow',
		field: 'enthalpy_flow',
		unit: 'cal/sec'
	},
	average_mw: {
		cn: '平均分子量',
		en: 'Average MW',
		field: 'Average MW',
		unit: ''
	},
	mole_flows: {
		cn: '摩尔流量',
		en: 'Mole Flows',
		field: 'mole_flows',
		unit: 'kmol/hr'
	},
	mass_flows: {
		cn: '质量流量',
		en: 'Mass Flows',
		field: 'mass_flows',
		unit: 'kg/hr'
	}
}

export const components = [
	replaceFormulaStr('C4H10-1'),
	replaceFormulaStr('C4H8-2'),
	replaceFormulaStr('C5H12-1'),
	replaceFormulaStr('C5H10-2'),
	replaceFormulaStr('C6H14-1'),
	replaceFormulaStr('C6H12-2'),
	replaceFormulaStr('C7H16-1'),
	replaceFormulaStr('C7H14-2'),
	replaceFormulaStr('C8H18-1'),
	replaceFormulaStr('C8H16-2'),
	replaceFormulaStr('C9H20-1'),
	replaceFormulaStr('C9H18-2'),
	replaceFormulaStr('C10H22-1'),
	replaceFormulaStr('C10H20-2'),
	replaceFormulaStr('C11H24-1'),
	replaceFormulaStr('C11H22-2'),
	replaceFormulaStr('C12H26-1'),
	replaceFormulaStr('C12H24-2'),
	replaceFormulaStr('C13H26-2'),
	replaceFormulaStr('C13H26-2'),
]

export const inputStreams: any = [
	{
		name: 'input',
		from: '',
		to: 'TC5',
		phase: 'Liquid',
		params: [
			{
				...streamsParams.temperature,
				value: 20
			},
			{
				...streamsParams.pressure,
				value: 4
			},
			{
				...streamsParams.molar_enthalpy,
				value: -49738.2045299233
			},
			{
				...streamsParams.mass_enthalpy,
				value: -484.254315570686
			},
			{
				...streamsParams.molar_entropy,
				value: -177.10007018971
			},
			{
				...streamsParams.mass_entropy,
				value: -1.72425752171337
			},
			{
				...streamsParams.enthalpy_flow,
				value: -244817.459538514
			},
			{
				...streamsParams.average_mw,
				value: 102.710916414462
			},
			{
				...streamsParams.mole_flows,
				value: 17.7196354928417
			},
			{
				...streamsParams.mass_flows,
				value: 1820
			}
		],
		components: [
			0.001,
			0.003,
			0.028,
			0.023,
			0.156,
			0.014,
			0.276,
			0.013,
			0.006,
			0.295,
			0.003,
			0.126,
			0.001,
			0.039,
			0.009,
			0.004,
			0.002,
			0.001,
			0.000,
			0.000
		]
	}
]




export const simpleSource: any = [
  {
    id: "1",
    name: "碳分离工程项目",
    pic: i_1,
		desc:'利用化学溶剂（如胺类溶液）与二氧化碳发生化学反应，将二氧化碳吸收。',
    date: "2024-11-27",
  },
  {
    id: "2",
    name: "甲醇精馏项目",
    pic: i_2,
		desc:'甲醇精馏是基于混合物中各组分挥发性的差异来实现分离的。',
    date: "2024-10-28",
  },
  {
    id: "3",
    name: "费托尾气处理",
    pic: i_3,
		desc:'利用吸附剂在不同压力下对氢气吸附性能的差异，在高压下吸附尾气中的氢气，然后通过降低压力使氢气解吸。',
    date: "2024-10-07",
  },
  {
    id: "4",
    name: "污水处理项目",
    pic: i_4,
		desc:'通过加入氧化剂（如臭氧、过氧化氢、高锰酸钾等）来氧化污水中的有机物、还原性物质和病原体。',
    date: "2024-09-01",
  },
  {
    id: "5",
    name: "合成氨项目",
    pic: i_5,
		desc:'反应需要在较高的温度下进行，一般在 400 - 500℃。',
    date: "2024-08-22",
  },
  {
    id: "6",
    name: "电解铝项目",
    pic: i_6,
		desc:'气体精炼主要是采用氯气或氩气等气体，通过吹气等方式将杂质去除。',
    date: "2024-08-07",
  },
];

export const simpleSource2: any = [
  {
    id: "1",
    name: "碳分离工程项目",
    pic: i_1,
		desc:'利用化学溶剂（如胺类溶液）与二氧化碳发生化学反应，将二氧化碳吸收。',
    date: "2024-11-27",
  },
  {
    id: "2",
    name: "甲醇精馏项目",
    pic: i_2,
		desc:'甲醇精馏是基于混合物中各组分挥发性的差异来实现分离的。',
    date: "2024-10-28",
  },
  {
    id: "3",
    name: "费托尾气处理",
    pic: i_3,
		desc:'利用吸附剂在不同压力下对氢气吸附性能的差异，在高压下吸附尾气中的氢气，然后通过降低压力使氢气解吸。',
    date: "2024-10-07",
  },
  {
    id: "4",
    name: "污水处理项目",
    pic: i_4,
		desc:'通过加入氧化剂（如臭氧、过氧化氢、高锰酸钾等）来氧化污水中的有机物、还原性物质和病原体。',
    date: "2024-09-01",
  },
  {
    id: "5",
    name: "合成氨项目",
    pic: i_5,
		desc:'反应需要在较高的温度下进行，一般在 400 - 500℃。',
    date: "2024-08-22",
  },
  {
    id: "6",
    name: "电解铝项目",
    pic: i_6,
		desc:'气体精炼主要是采用氯气或氩气等气体，通过吹气等方式将杂质去除。',
    date: "2024-08-07",
  },
  {
    id: "7",
    name: "碳分离工程项目",
    pic: i_1,
		desc:'利用化学溶剂（如胺类溶液）与二氧化碳发生化学反应，将二氧化碳吸收。',
    date: "2024-11-27",
  },
  {
    id: "8",
    name: "甲醇精馏项目",
    pic: i_2,
		desc:'甲醇精馏是基于混合物中各组分挥发性的差异来实现分离的。',
    date: "2024-10-28",
  },
  {
    id: "9",
    name: "费托尾气处理",
    pic: i_3,
		desc:'利用吸附剂在不同压力下对氢气吸附性能的差异，在高压下吸附尾气中的氢气，然后通过降低压力使氢气解吸。',
    date: "2024-10-07",
  },
  {
    id: "10",
    name: "污水处理项目",
    pic: i_4,
		desc:'通过加入氧化剂（如臭氧、过氧化氢、高锰酸钾等）来氧化污水中的有机物、还原性物质和病原体。',
    date: "2024-09-01",
  },
  {
    id: "11",
    name: "合成氨项目",
    pic: i_5,
		desc:'反应需要在较高的温度下进行，一般在 400 - 500℃。',
    date: "2024-08-22",
  },
  {
    id: "12",
    name: "电解铝项目",
    pic: i_6,
		desc:'气体精炼主要是采用氯气或氩气等气体，通过吹气等方式将杂质去除。',
    date: "2024-08-07",
  },
];

export const elementsLibs:any=[
	{
		type:'反应器',
		children:[
			{
				"name": "固定绝热反应器",
				"desc": "固定绝热反应器",
				"icon": "/icon/reactor_1.svg",
				"params": [
					{
						"p_name": "进口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "出口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "进口压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "出口压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "阻力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "功率",
						"p_unit": [
							"KW"
						]
					},
					{
						"p_name": "计算模型",
						"p_unit": [
							"平衡",
							"动力学",
							"转化率"
						]
					}
				]
			},
			{
				"name": "固定等温反应器",
				"desc": "固定等温反应器",
				"icon": "/icon/reactor_2.svg",
				"params": [
					{
						"p_name": "进口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "出口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "进口压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "出口压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "阻力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "功率",
						"p_unit": [
							"KW"
						]
					},
					{
						"p_name": "计算模型",
						"p_unit": [
							"平衡",
							"动力学",
							"转化率"
						]
					}
				]
			},
			{
				"name": "搅拌反应釜",
				"desc": "搅拌反应釜",
				"icon": "/icon/reactor_3.svg",
				"params": [
					{
						"p_name": "进口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "出口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "进口压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "出口压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "阻力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "功率",
						"p_unit": [
							"KW"
						]
					},
					{
						"p_name": "计算模型",
						"p_unit": [
							"平衡",
							"动力学",
							"转化率"
						]
					}
				]
			},
			{
				"name": "脱硫塔",
				"desc": "近一步吸收煤气中的硫化氢",
				"icon": "/icon/tower_1.svg",
				"params": [
					{
						"p_name": "温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "塔板数",
						"p_unit": [
							"个",
							"层"
						]
					},
					{
						"p_name": "进料",
						"p_unit": [
							"位置1",
							"..."
						]
					},
					{
						"p_name": "出料",
						"p_unit": [
							"位置1",
							"..."
						]
					}
				]
			},
			{
				"name": "再生塔",
				"desc": "将脱硫塔与湍球塔吸收的硫化氢氧化，以泡沫形式从顶部溢出",
				"icon": "/icon/tower_2.svg",
				"params": [
					{
						"p_name": "温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "塔板数",
						"p_unit": [
							"个",
							"层"
						]
					},
					{
						"p_name": "进料",
						"p_unit": [
							"位置1",
							"..."
						]
					},
					{
						"p_name": "出料",
						"p_unit": [
							"位置1",
							"..."
						]
					}
				]
			},
			{
				"name": "湍球塔",
				"desc": "初步吸收煤气中的硫化氢",
				"icon": "/icon/tower_3.svg",
				"params": [
					{
						"p_name": "温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "塔板数",
						"p_unit": [
							"个",
							"层"
						]
					},
					{
						"p_name": "进料",
						"p_unit": [
							"位置1",
							"..."
						]
					},
					{
						"p_name": "出料",
						"p_unit": [
							"位置1",
							"..."
						]
					}
				]
			},
			{
				"name": "脱苯塔",
				"desc": "将焦油中的苯脱附出来",
				"icon": "/icon/tower_4.svg",
				"params": [
					{
						"p_name": "温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "塔板数",
						"p_unit": [
							"个",
							"层"
						]
					},
					{
						"p_name": "进料",
						"p_unit": [
							"位置1",
							"..."
						]
					},
					{
						"p_name": "出料",
						"p_unit": [
							"位置1",
							"..."
						]
					}
				]
			},
			{
				"name": "洗苯塔",
				"desc": "用焦油洗涤煤气中的苯",
				"icon": "/icon/tower_5.svg",
				"params": [
					{
						"p_name": "温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "塔板数",
						"p_unit": [
							"个",
							"层"
						]
					},
					{
						"p_name": "进料",
						"p_unit": [
							"位置1",
							"..."
						]
					},
					{
						"p_name": "出料",
						"p_unit": [
							"位置1",
							"..."
						]
					}
				]
			},
			{
				"name": "蒸氨塔",
				"desc": "使溶解于循环水中的氨气释放的解吸塔",
				"icon": "/icon/tower_6.svg",
				"params": [
					{
						"p_name": "温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "塔板数",
						"p_unit": [
							"个",
							"层"
						]
					},
					{
						"p_name": "进料",
						"p_unit": [
							"位置1",
							"..."
						]
					},
					{
						"p_name": "出料",
						"p_unit": [
							"位置1",
							"..."
						]
					}
				]
			},
			{
				"name": "加热炉",
				"desc": "燃烧焦炉煤气产生热量并加热导热油",
				"icon": "/icon/boiler_1.svg",
				"params": [
					{
						"p_name": "进口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "出口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "进口压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "出口压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "阻力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "功率",
						"p_unit": [
							"KW"
						]
					},
					{
						"p_name": "焦油煤气量",
						"p_unit": [
							"kg/m3"
						]
					},
					{
						"p_name": "燃烧反应模型",
						"p_unit": [
							"平衡",
							"动力学",
							"转化率"
						]
					}
				]
			},
			{
				"name": "重整炉",
				"desc": "焦炉煤气与氧、蒸汽反应转化为CO",
				"icon": "/icon/boiler_2.svg",
				"params": [
					{
						"p_name": "进口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "出口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "进口压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "出口压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "阻力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "功率",
						"p_unit": [
							"KW"
						]
					},
					{
						"p_name": "焦油煤气量",
						"p_unit": [
							"kg/m3"
						]
					},
					{
						"p_name": "燃烧反应模型",
						"p_unit": [
							"平衡",
							"动力学",
							"转化率"
						]
					}
				]
			}
		]
	},
	{
		type:'分离器',
		children:[
			{
				"name": "板框压滤机",
				"desc": "用于固体和液体分离",
				"icon": "/icon/separator_1.svg",
				"params": [
					{
						"p_name": "进口流量",
						"p_unit": [
							"m³/h"
						]
					},
					{
						"p_name": "进口压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "出口液体流量",
						"p_unit": [
							"m³/h"
						]
					},
					{
						"p_name": "出口固体量",
						"p_unit": [
							"%"
						]
					},
					{
						"p_name": "含水量",
						"p_unit": [
							"%"
						]
					},
					{
						"p_name": "过滤面积",
						"p_unit": [
							"m³"
						]
					}
				]
			},
			{
				"name": "布袋除尘器",
				"desc": "将含有固体的气体进行气固分离",
				"icon": "/icon/separator_2.svg",
				"params": [
					{
						"p_name": "流量",
						"p_unit": [
							"m³/h"
						]
					},
					{
						"p_name": "压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "出口气体流量",
						"p_unit": [
							"m³/h"
						]
					},
					{
						"p_name": "出口固体含量",
						"p_unit": [
							"%"
						]
					}
				]
			},
			{
				"name": "离心机",
				"desc": "将含有固体的液体进行固液分离",
				"icon": "/icon/separator_3.svg",
				"params": [
					{
						"p_name": "流量",
						"p_unit": [
							"m³/h"
						]
					},
					{
						"p_name": "压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "出口液体流量",
						"p_unit": [
							"m³/h"
						]
					},
					{
						"p_name": "出口固体含水量",
						"p_unit": [
							"%"
						]
					}
				]
			},
			{
				"name": "电捕焦油器",
				"desc": "将煤气中的焦油捕集下来，除油",
				"icon": "/icon/separator_4.svg",
				"params": [
					{
						"p_name": "流量",
						"p_unit": [
							"m³/h"
						]
					},
					{
						"p_name": "压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "出口液体流量",
						"p_unit": [
							"m³/h"
						]
					},
					{
						"p_name": "出口焦油量",
						"p_unit": [
							"%"
						]
					},
					{
						"p_name": "电功率",
						"p_unit": [
							"KW"
						]
					},
					{
						"p_name": "分离效率"
					}
				]
			},
			{
				"name": "结晶槽",
				"desc": "将含有固体的液体进行沉降，浓缩",
				"icon": "/icon/separator_5.svg",
				"params": [
					{
						"p_name": "流量",
						"p_unit": [
							"m³/h"
						]
					},
					{
						"p_name": "压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "出口液体流量",
						"p_unit": [
							"m³/h"
						]
					},
					{
						"p_name": "出口固体含量",
						"p_unit": [
							"%"
						]
					}
				]
			},
			{
				"name": "结晶器",
				"desc": "用于将氨吸收，硫铵饱和以固体结晶析出",
				"icon": "/icon/separator_6.svg",
				"params": [
					{
						"p_name": "流量",
						"p_unit": [
							"m³/h"
						]
					},
					{
						"p_name": "压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "出口液体流量",
						"p_unit": [
							"m³/h"
						]
					},
					{
						"p_name": "出口固体含量",
						"p_unit": [
							"%"
						]
					}
				]
			},
			{
				"name": "气液分离器",
				"desc": "将含有液体的气体进行气液分离",
				"icon": "/icon/separator_7.svg",
				"params": [
					{
						"p_name": "流量",
						"p_unit": [
							"m³/h"
						]
					},
					{
						"p_name": "压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "出口气体流量",
						"p_unit": [
							"m³/h"
						]
					},
					{
						"p_name": "出口液体量",
						"p_unit": [
							"%"
						]
					},
					{
						"p_name": "功率",
						"p_unit": [
							"KW"
						]
					},
					{
						"p_name": "分离效率"
					}
				]
			},
			{
				"name": "旋风除尘器",
				"desc": "将含有固体的气体进行气固分离",
				"icon": "/icon/separator_8.svg",
				"params": [
					{
						"p_name": "流量",
						"p_unit": [
							"m³/h"
						]
					},
					{
						"p_name": "压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "出口气体流量",
						"p_unit": [
							"m³/h"
						]
					},
					{
						"p_name": "出口固体含量",
						"p_unit": [
							"%"
						]
					}
				]
			}
		]
	},
	{
		type:'换热器',
		children:[
			{
				"name": "洗油再生器",
				"desc": "富油用蒸汽升温至沸腾",
				"icon": "/icon/heatExchanger_1.svg",
				"params": [
					{
						"p_name": "进口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "出口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "气化率",
						"p_unit": [
							"%"
						]
					},
					{
						"p_name": "功率",
						"p_unit": [
							"KW"
						]
					}
				]
			},
			{
				"name": "初冷器",
				"desc": "用循环水间接冷却煤气，并用焦油，冷凝液喷洒直接冷却煤气",
				"icon": "/icon/heatExchanger_2.svg",
				"params": [
					{
						"p_name": "进口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "出口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "功率",
						"p_unit": [
							"KW"
						]
					}
				]
			},
			{
				"name": "二段油水换热器",
				"desc": "用制冷水冷却贫油",
				"icon": "/icon/heatExchanger_5.svg",
				"params": [
					{
						"p_name": "进口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "出口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "功率",
						"p_unit": [
							"KW"
						]
					}
				]
			},
			{
				"name": "终冷塔",
				"desc": "用循环水冷却煤气",
				"icon": "/icon/heatExchanger_4.svg",
				"params": [
					{
						"p_name": "进口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "出口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "功率",
						"p_unit": [
							"KW"
						]
					}
				]
			},
			{
				"name": "换热器",
				"desc": "热交换，将经过换热器得流体升温或降温",
				"icon": "/icon/heatExchanger_5.svg",
				"params": [
					{
						"p_name": "进口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "出口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "功率",
						"p_unit": [
							"KW"
						]
					}
				]
			},
			{
				"name": "冷凝冷却器",
				"desc": "将高温富油冷却",
				"icon": "/icon/heatExchanger_6.svg",
				"params": [
					{
						"p_name": "进口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "出口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "功率",
						"p_unit": [
							"KW"
						]
					}
				]
			}
		]
	},
	{
		type:'泵',
		children:[
			{
				"name": "泵",
				"desc": "用于液体流体输送",
				"icon": "/icon/pump_1.svg",
				"params": [
					{
						"p_name": "出口压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "升压",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "功率",
						"p_unit": [
							"KW"
						]
					}
				]
			},
			{
				"name": "压缩机",
				"desc": "用于气体加压",
				"icon": "/icon/pump_2.svg",
				"params": [
					{
						"p_name": "进口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "出口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "进口压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "出口压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "升压",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "功率",
						"p_unit": [
							"KW"
						]
					}
				]
			},
			{
				"name": "风机",
				"desc": "用于煤气加压",
				"icon": "/icon/pump_3.svg",
				"params": [
					{
						"p_name": "进口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "出口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "进口压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "出口压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "升压",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "功率",
						"p_unit": [
							"KW"
						]
					}
				],
				"status": {
					"in_temperature": {
						"current": 198,
						"standard": [190,210],
						"unit": "℃"
					},
					"out_temperature": {
						"current": 104,
						"standard": [90,110],
						"unit": "℃"
					},
					"in_pressture": {
						"current": 10,
						"standard": [40,60],
						"p_unit": "KPa"
					},
					"out_pressture": {
						"current": 87,
						"standard": [90,110],
						"p_unit": "KPa"
					},
					"speed": {
						"current": 1430,
						"standard": [1400,1450],
						"p_unit": "r/min"
					},
					"flow": {
						"current": 20988,
						"standard": [17670,25916],
						"p_unit": "m3/h"
					}
				}
			}
		]
	},
	{
		type:'容器',
		children:[
			{
				"name": "储罐（大）",
				"desc": "液体储存",
				"icon": "/icon/container_1.svg",
				"params": [
					{
						"p_name": "温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "容积",
						"p_unit": [
							"m³"
						]
					}
				]
			},
			{
				"name": "储罐（小）",
				"desc": "液体储存",
				"icon": "/icon/container_2.svg",
				"params": [
					{
						"p_name": "温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "容积",
						"p_unit": [
							"m³"
						]
					}
				]
			},
			{
				"name": "搅拌罐",
				"desc": "含固液体储存",
				"icon": "/icon/container_3.svg",
				"params": [
					{
						"p_name": "温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "容积",
						"p_unit": [
							"m³"
						]
					},
					{
						"p_name": "搅拌功率",
						"p_unit": [
							"KW"
						]
					}
				]
			},
			{
				"name": "气柜",
				"desc": "低压气体储存",
				"icon": "/icon/container_4.svg",
				"params": [
					{
						"p_name": "进口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "出口温度",
						"p_unit": [
							"℃"
						]
					},
					{
						"p_name": "进口压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "出口压力",
						"p_unit": [
							"KPa"
						]
					},
					{
						"p_name": "容积",
						"p_unit": [
							"m³"
						]
					}
				]
			}
		]
		
	},
	{
		type:'产品',
		children:[
			{
				"name": "蒸汽",
				"desc": "",
				"icon": "/icon/products_2.svg",
				"params": [
					{
						"p_name": "产量",
						"p_unit": [
							"吨"
						]
					},
					{
						"p_name": "产率",
						"p_unit": [
							"%"
						]
					}
				]
			},
			{
				"name": "煤气",
				"desc": "",
				"icon": "/icon/products_3.svg",
				"params": [
					{
						"p_name": "产量",
						"p_unit": [
							"吨"
						]
					},
					{
						"p_name": "产率",
						"p_unit": [
							"%"
						]
					}
				]
			},
			{
				"name": "废气",
				"desc": "",
				"icon": "/icon/products_10.svg",
				"params": [
					{
						"p_name": "产量",
						"p_unit": [
							"吨"
						]
					},
					{
						"p_name": "产率",
						"p_unit": [
							"%"
						]
					}
				]
			},
			{
				"name": "水",
				"desc": "",
				"icon": "/icon/products_1.svg",
				"params": [
					{
						"p_name": "产量",
						"p_unit": [
							"吨"
						]
					},
					{
						"p_name": "产率",
						"p_unit": [
							"%"
						]
					}
				]
			},
			{
				"name": "煤焦油",
				"desc": "",
				"icon": "/icon/products_4.svg",
				"params": [
					{
						"p_name": "产量",
						"p_unit": [
							"吨"
						]
					},
					{
						"p_name": "产率",
						"p_unit": [
							"%"
						]
					}
				]
			},
			{
				"name": "苯",
				"desc": "",
				"icon": "/icon/products_5.svg",
				"params": [
					{
						"p_name": "产量",
						"p_unit": [
							"吨"
						]
					},
					{
						"p_name": "产率",
						"p_unit": [
							"%"
						]
					}
				]
			},
			{
				"name": "煤",
				"desc": "",
				"icon": "/icon/products_6.svg",
				"params": [
					{
						"p_name": "产量",
						"p_unit": [
							"吨"
						]
					},
					{
						"p_name": "产率",
						"p_unit": [
							"%"
						]
					}
				]
			},
			{
				"name": "焦炭",
				"desc": "",
				"icon": "/icon/products_7.svg",
				"params": [
					{
						"p_name": "产量",
						"p_unit": [
							"吨"
						]
					},
					{
						"p_name": "产率",
						"p_unit": [
							"%"
						]
					}
				]
			},
			{
				"name": "硫磺",
				"desc": "",
				"icon": "/icon/products_8.svg",
				"params": [
					{
						"p_name": "产量",
						"p_unit": [
							"吨"
						]
					},
					{
						"p_name": "产率",
						"p_unit": [
							"%"
						]
					}
				]
			},
			{
				"name": "硫铵",
				"desc": "",
				"icon": "/icon/products_9.svg",
				"params": [
					{
						"p_name": "产量",
						"p_unit": [
							"吨"
						]
					},
					{
						"p_name": "产率",
						"p_unit": [
							"%"
						]
					}
				]
			},
			{
				"name": "灰分灰尘废渣",
				"desc": "",
				"icon": "/icon/products_11.svg",
				"params": [
					{
						"p_name": "产量",
						"p_unit": [
							"吨"
						]
					},
					{
						"p_name": "产率",
						"p_unit": [
							"%"
						]
					}
				]
			}
		]
		
	}
]
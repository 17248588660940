import { Route, Routes, NavLink, Link } from 'react-router-dom';
import Navs from '@/config/routes';
import { webTitle } from '@/config/config';
import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import {Fragment} from 'react'
import weblogo from '@/assets/icons/logo.png'

const App = () => {

  return (
    <ul className="routes">
      <li className="webTitle">
        <Link to="/"><img src={weblogo} alt="" style={{width:'160px'}} /></Link>
      </li>
      {Navs.map((item: any) => {
        if (item.showMenu !== false) {
          return (
            <Fragment key={item.path}>
              {item.showSubs ? (
                <Dropdown
                  arrow={true}
                  menu={{
                    items: item.children.map((i: any, index: any) => {
                      return i.showMenu !== false
                        ? {
                            key: index.toString(),
                            // danger: true,
                            label: (
                              <NavLink
                                className={({ isActive }) => {
                                  return isActive ? 'activeSubRoute' : '';
                                }}
                                to={i.path}
                              >
                                {i.title}
                              </NavLink>
                            ),
                          }
                        : '';
                    }),
                  }}
                >
                  <li className='hasChild'>
                    <NavLink
                      className={({ isActive }) => {
                        return isActive ? 'activeRoute' : '';
                      }}
                      to={item.path}
                    >
                      {item.title}
                    </NavLink>
                  </li>
                </Dropdown>
              ) : (
                <li key={item.path}>
                  <NavLink
                    className={({ isActive }) => {
                      return isActive ? 'activeRoute' : '';
                    }}
                    to={item.path}
                  >
                    {item.title}
                  </NavLink>
                </li>
              )}
            </Fragment>
          );
        }
      })}
    </ul>
  );
};

export default App;

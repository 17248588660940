import React, { useState, useEffect } from "react";
import TopToolBar from "./TopToolBar";
import Editor from './Editor'
import Aside from './FlowEditor/Sidebar'

const Component = (props: any) => {
  const {
    showLeft,
    setShowLeft,
    showElementsBar,
    setShowElementsBar,
    curmode,
    setCurmode
   } = props;
  
  return (
    <div className={`mc_right${showLeft ? "" : " hide"}`}>
      <TopToolBar setShowElementsBar={setShowElementsBar} showElementsBar={showElementsBar} setShowLeft={setShowLeft} showLeft={showLeft} curmode={curmode} setCurmode={setCurmode} />
      <Editor showElementsBar={showElementsBar} setShowElementsBar={setShowElementsBar} />
      {
        showElementsBar?<Aside 
        showElementsBar={showElementsBar}
        setShowElementsBar={setShowElementsBar}
        curmode={curmode}
        setCurmode={setCurmode}
        />:''
      }
      
    </div>
  );
};

export default Component;

import React, { useState, useEffect } from "react";
import PageTop from "./Components/PageTop";
import { themsField } from "@/config/config";
import Routers from "@/Components/PageTop/Routers";

const App = () => {
  const [curTheme, setCurTheme] = useState<any>("dark");
  useEffect(() => {
    setCurTheme(localStorage.getItem(themsField) ? localStorage.getItem(themsField) : "dark");
  }, []);

  return (
    <div className={curTheme}>
      <PageTop curTheme={curTheme} setCurTheme={setCurTheme} />
      <div className="pageContent">
        <Routers />
      </div>
    </div>
  );
};

export default App;

import React, { memo, CSSProperties, useCallback } from "react";
import {
  Handle,
  Position,
  NodeProps,
  Connection,
  Edge,
  useOnViewportChange,
  Viewport,
  Background,
} from "@xyflow/react";

import type { Reactor4 } from ".";

const targetHandleStyle: CSSProperties = { background: "#555" };
const sourceHandleStyleA: CSSProperties = { ...targetHandleStyle, top: 0 };

const onConnect = (params: Connection | Edge) =>
  console.log("handle onConnect", params);

function Reactor4({ data, isConnectable }: NodeProps<Reactor4>) {
  const onStart = useCallback(
    (viewport: Viewport) => console.log("onStart", viewport),
    []
  );
  const onChange = useCallback(
    (viewport: Viewport) => console.log("onChange", viewport),
    []
  );
  const onEnd = useCallback(
    (viewport: Viewport) => console.log("onEnd", viewport),
    []
  );

  useOnViewportChange({
    onStart,
    onChange,
    onEnd,
  });

  return (
    <div
      style={{
        width: "150px",
        height: "300px",
        backgroundImage: "url(/reactor4.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        // backgroundColor: "transparent",
      }}
    >
      <div style={{ position: "absolute", top: 0, right: 0}}>
        <strong>反应器 4</strong>
      </div>
      <Handle
        type="source"
        position={Position.Top}
        id="a"
        style={sourceHandleStyleA}
        isConnectable={isConnectable}
        onMouseDown={(e) => {
          console.log("You trigger mousedown event", e);
        }}
      />
    </div>
  );
}

export default memo(Reactor4);

import React, { useState, useEffect } from "react";
import { Popconfirm, Spin, message, Space } from "antd";
import dayjs from "dayjs";
import { simpleSource } from '@/config/const'
import { useNavigate } from 'react-router-dom';
import ImageType from './ShowImgType';
import TableType from './ShowTableType'
import PageTitle from '@/Components/PageTitle';


const Component = () => {
  const history = useNavigate();
  const [source, setSource] = useState<any>(null);
  const [showType, setShowType] = useState<any>('img')
  const [count, setCount] = useState<any>(null);
  useEffect(() => {
    setTimeout(() => {
      setSource(simpleSource);
      setCount(simpleSource.length);
    }, 1000);
  }, []);

  const del_confirm: any = (e: any) => {
    console.log(e);
    message.success("删除成功");
  };

  return (
    <div className="indexShow">
      {source ? (
        <div className="indexShowMain">
          <PageTitle
            title={`项目列表：（${count}）`}
            extra={
              <Space>
                <ul className='listType'>
                  <li className={`${showType === 'img' ? 'active' : ''}`}
                    onClick={() => setShowType('img')}
                  ><div className={`icon imgType${showType === 'img' ? '' : ' off'}`} /></li>
                  <li className={`${showType === 'table' ? 'active' : ''}`}
                    onClick={() => setShowType('table')}
                  ><div className={`icon tableType${showType === 'table' ? '' : ' off'}`} /></li>
                </ul>
                <div className="button new" style={{ marginLeft: '2rem' }}>+ 新建项目</div>
              </Space>
            }
          />
          {
            source ?
              showType === 'img' ?
                <ImageType
                  source={source}
                  del_confirm={del_confirm}
                /> :
                <TableType
                  source={source}
                  del_confirm={del_confirm}
                />
              : ''
          }
          <ul className="index_pageSep">
            <li className="active">1</li>
            <li>2</li>
            <li>3</li>
            <li>4</li>
            <li>5</li>
            <li className="next">下一页</li>
          </ul>
        </div>
      ) : (
        <div className="loadingArea">
          <Spin />
        </div>
      )}
    </div>
  );
};

export default Component;

import {
  useState,
  memo,
  FC,
  useMemo,
  ChangeEvent,
  MouseEvent,
  useRef,
} from 'react';
import {
  Handle,
  Position,
  useUpdateNodeInternals,
  NodeProps,
  Node
} from '@xyflow/react';
import styles from './index.module.css';

interface HandlePosition {
  top?: number;
  left?: number;
  position: Position;
}
export type MultiHandleNode = Node<
  { color: string; onChange: (event: ChangeEvent<HTMLInputElement>) => void },
  'multiHandleNode'
>;
const MultiHandleNode: FC<NodeProps> = ({ id, data }) => {
  const HANDLE_SIZE = 10;
  const [inputCount, setInputCount] = useState(0);
  const [inputPositions, setInputPositions] = useState<{
    [key: string]: HandlePosition;
  }>({});
  const [outputCount, setOutputCount] = useState(0);
  const [outputPositions, setOutputPositions] = useState<{
    [key: string]: HandlePosition;
  }>({});
  const [isDragging, setIsDragging] = useState(false);
  const updateNodeInternals = useUpdateNodeInternals();
  const nodeRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const calculateEdgePosition = (
    clientX: number,
    clientY: number,
    nodeRef: HTMLDivElement
  ): HandlePosition => {
    // Calculate position relative to the node
    const nodeRect = nodeRef.getBoundingClientRect();
    const relativeX =
      ((clientX - nodeRect.left) * nodeRef.clientWidth) / nodeRect.width;
    const relativeY =
      ((clientY - nodeRect.top) * nodeRef.clientHeight) / nodeRect.height;

    // Determine which edge is closest
    const distToLeft = Math.abs(relativeX);
    const distToRight = Math.abs(relativeX - nodeRef.clientWidth);
    const distToTop = Math.abs(relativeY);
    const distToBottom = Math.abs(relativeY - nodeRef.clientHeight);

    const minDist = Math.min(distToLeft, distToRight, distToTop, distToBottom);

    // Clamp the position to the node boundaries
    const clampedX = Math.max(0, Math.min(relativeX, nodeRef.clientWidth));
    const clampedY = Math.max(0, Math.min(relativeY, nodeRef.clientHeight));

    switch (minDist) {
      case distToLeft:
        return {
          left: 0,
          top: clampedY,
          position: Position.Left,
        };
      case distToRight:
        return {
          left: nodeRef.clientWidth,
          top: clampedY,
          position: Position.Right,
        };
      case distToTop:
        return {
          top: 0,
          left: clampedX,
          position: Position.Top,
        };
      case distToBottom:
        return {
          top: nodeRef.clientHeight,
          left: clampedX,
          position: Position.Bottom,
        };
      default:
        return {
          left: nodeRect.width,
          top: clampedY,
          position: Position.Right,
        };
    }
  };
  const calculateInsidePosition = (
    clientX: number,
    clientY: number,
    nodeRef: HTMLDivElement
  ): HandlePosition => {
    const nodeRect = nodeRef.getBoundingClientRect();
    
    // Calculate position relative to the node
    const relativeX = ((clientX - nodeRect.left) * nodeRef.clientWidth) / nodeRect.width;
    const relativeY = ((clientY - nodeRect.top) * nodeRef.clientHeight) / nodeRect.height;
    const padding = 0;
    const clampedX = Math.max(padding, Math.min(relativeX, nodeRef.clientWidth - padding));
    const clampedY = Math.max(padding, Math.min(relativeY, nodeRef.clientHeight - padding));
    // Calculate distances to each edge
    const distToLeft = relativeX;
    const distToRight = nodeRef.clientWidth - relativeX;
    const distToTop = relativeY;
    const distToBottom = nodeRef.clientHeight - relativeY;

    // Find the minimum distance
    const minDist = Math.min(distToLeft, distToRight, distToTop, distToBottom);
    let position = Position.Left;
    // Return position based on closest edge
    if (minDist === distToLeft) {
      
        position=  Position.Left
      
    } else if (minDist === distToRight) {

        position=  Position.Right
      
    } else if (minDist === distToTop) {
      
        position=Position.Top
      
    } else {
      
        position= Position.Bottom
      
    }
    return {
      top: clampedY,
      left: clampedX,
      position
    }
  };
  const onContextMenu = (event: MouseEvent, handleId: string) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.button !== 2) return;

    const node = nodeRef.current;
    if (!node) return;

    setIsDragging(true);

    const onDrag = (moveEvent: MouseEvent) => {
      const newPosition = calculateInsidePosition(
        moveEvent.clientX,
        moveEvent.clientY,
        node
      );
      if (handleId.indexOf('input') > -1) {
        setInputPositions((prev) => ({
          ...prev,
          [handleId]: newPosition,
        }));
      }
      if (handleId.indexOf('output') > -1) {
        setOutputPositions((prev) => ({
          ...prev,
          [handleId]: newPosition,
        }));
      }
      updateNodeInternals([id]);
    };

    const onDragEnd = () => {
      setIsDragging(false);
      window.removeEventListener('mousemove', onDrag as any);
      window.removeEventListener('mouseup', onDragEnd);
    };

    window.addEventListener('mousemove', onDrag as any);
    window.addEventListener('mouseup', onDragEnd);
  };
  const inputs = useMemo(
    () =>
      Array.from({ length: inputCount }, (x, i) => {
        const handleId = `input-${i}`;
        const defaultPosition = {
          top: i * (HANDLE_SIZE + 20) + 20,
          left: 0,
          position: Position.Left,
        };
        const position = inputPositions[handleId] || defaultPosition;

        return (
          <Handle
            key={handleId}
            type='target'
            position={position.position}
            id={handleId}
            style={{
              top: position.top,
              left: position.left,
              transform: 'translate(-50%, -50%)',
              background: 'green',
              pointerEvents: 'all',
              width: HANDLE_SIZE,
              height: HANDLE_SIZE,
            }}
            onContextMenu={(e) => onContextMenu(e, handleId)}
            onMouseDown={(e) => {
              if (e.button === 2) {
                onContextMenu(e, handleId);
              }
            }}
          />
        );
      }),
    [inputCount, inputPositions, isDragging]
  );

  const outputs = useMemo(
    () =>
      Array.from({ length: outputCount }, (x, i) => {
        const handleId = `output-${i}`;
        const defaultPosition = {
          top: i * (HANDLE_SIZE + 20) + 20,
          left: nodeRef.current?.clientWidth,
          position: Position.Right,
        };
        const position = outputPositions[handleId] || defaultPosition;

        return (
          <Handle
            key={handleId}
            type='source'
            position={position.position}
            id={handleId}
            style={{
              top: position.top,
              left: position.left,
              transform: 'translate(-50%, -50%)',
              background: 'orange',
              pointerEvents: 'all',
              width: HANDLE_SIZE,
              height: HANDLE_SIZE,
            }}
            onContextMenu={(e) => onContextMenu(e, handleId)}
            onMouseDown={(e) => {
              if (e.button === 2) {
                onContextMenu(e, handleId);
              }
            }}
          />
        );
      }),
    [outputCount, outputPositions, isDragging]
  );

  const dragHandleStyle = {
    display: 'inline-block',
    width: 25,
    height: 25,
    backgroundColor: 'teal',
    marginLeft: 5,
    borderRadius: '50%',
  };

  return (
    <div>
      <div
        className={styles.node}
        ref={nodeRef}
        onContextMenu={(e) => {
          e.preventDefault();
          if (menuRef.current && nodeRef.current) {
            const rect = nodeRef.current.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;
            menuRef.current.style.display = 'block';
            menuRef.current.style.left = `${x}px`;
            menuRef.current.style.top = `${y}px`;
            console.log('Menu position:', x, y);
          }
        }}
      >
        {inputs}
        {outputs}
      </div>
      <div
        id='contextMenu'
        ref={menuRef}
        className={styles.menu}
      >
        <div
          className={styles.menuItem}
          onClick={() => {
            setInputCount(prev => prev + 1);
            updateNodeInternals([id]);
            if (menuRef.current) menuRef.current.style.display = 'none';
          }}
        >
          添加入口
        </div>
        <div className={styles.divider} />
        <div
          className={styles.menuItem}
          onClick={() => {
            setOutputCount(prev => prev + 1);
            updateNodeInternals([id]);
            if (menuRef.current) menuRef.current.style.display = 'none';
          }}
        >
          添加出口
        </div>
        <div className={styles.divider} />
        <div
          className={styles.menuItem}
          onClick={() => {
            if (menuRef.current) menuRef.current.style.display = 'none';
          }}
        >
          取消
        </div>
      </div>
    </div>
  );
};

export default memo(MultiHandleNode);

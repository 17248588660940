import React, { useState, useEffect } from 'react';

const Component = (props: any) => {
return (
<>
info
</>
);
};

export default Component;
import React, { useState, useEffect } from 'react';
import PageTitle from '@/Components/PageTitle';
import { simpleSource2 } from "@/config/const";
import { getQueryString } from '@/config/func'
import { Space } from 'antd';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';


const Component = (props: any) => {
  const [source, setSource] = useState<any>(null);
  const [curSource, setCurSource] = useState<any>(null);
  const history = useNavigate();

  useEffect(() => {
    const c: any = getQueryString('k') ? simpleSource2.find((item: any) => item.id === getQueryString('k')) : simpleSource2[0]
    setSource(simpleSource2)
    setCurSource(c)
  }, [getQueryString('k')])

  // console.log(source);

  return (
    <div className="indexShow">
      <div className="indexShowMain">
        <PageTitle
          title={curSource ? <Space>{curSource.name}<div className="button edit" style={{ marginLeft: '2rem' }}><Space><span className='icon edit'></span></Space></div></Space> : ''}
          extra={
            <Space>
              <div className="button new" style={{ marginLeft: '2rem' }}>+ 新建流程</div>
            </Space>
          }
        />
        <div className='page_Content'>
          <div className='content_top'>
            <div className='p_img'>
              <div className='showSImg'>
                <div className='showSImg_inner'><img src={curSource?.pic} alt="" /></div>
              </div>
            </div>
            <div className='p_descs'>
              <ul className='p_params'>
                <li>
                  <div>
                    <p>流程总数</p>
                    <p style={{ color: 'orange' }}>8</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>任务总数</p>
                    <p style={{ color: 'yellow' }}>18</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>创建日期</p>
                    <p>{curSource ? dayjs(curSource.date).format('YYYY-DD-DD') : ''}</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>创建人</p>
                    <p>张XX</p>
                  </div>
                </li>
              </ul>
              <div className='p_descs_detail mt2'>
                <div className='p_descs_title t2'>项目概述：</div>
                <div className='p_descs_detail_content'>{curSource?.desc}{curSource?.desc}{curSource?.desc}{curSource?.desc}</div>
              </div>
            </div>
          </div>
          <div className='content_bottom mt2'>
            <div className='t3 aqua'>已创建的流程：</div>
            <ul className='flowImgList mt1'>
              {
                ['一', '二', '三', '四', '五', '六', '七', '八'].map((item: any, index: any) => {
                  return (
                    <li key={index} 
                    onClick={() => history(`/flowview?k=${curSource.id}&f=${index+1}`)}
                    >
                      <div className='imgInner'>
                        <div className='showSImg'>
                          <div className='showSImg_inner'><img src={`/imgs/${index+1}.png`} alt="" /></div>
                        </div>
                      </div>
                      <p>流程{item}</p>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Component;
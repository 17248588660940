import React, { useState, useEffect } from "react";
import { userInfoField } from "@/config/config";
import { UserOutlined, DownOutlined, MenuOutlined, CaretDownOutlined } from "@ant-design/icons";
import { Avatar, Space, Dropdown, Divider } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Link } from "react-router-dom";

const userInfo = JSON.parse(sessionStorage.getItem(userInfoField) as any);

const Component = (props: any) => {
  const logout = () => {
    sessionStorage.removeItem(userInfoField);
    window.location.href = "/";
  };

  const items: MenuProps["items"] = [
    // {
    //   key: 'userInfo',
    //   label: (
    //     <Link to='/user/info'>
    //       我的资料
    //     </Link>
    //   ),
    // },
    {
      key: "logout",
      danger: true,
      label: (
        <div onClick={logout} style={{ padding: "5px 10px" }}>
          <LogoutOutlined /> 退出登录
        </div>
      ),
    },
  ];

  return (
    <div className="userPanel">
      <Dropdown menu={{ items }} arrow>
        <div className="userIcon">
          <Space size={10}>
            <UserOutlined style={{ fontSize: "1.8rem" }} />
            <CaretDownOutlined style={{ fontSize: "1rem" }} />
          </Space>
        </div>
      </Dropdown>
    </div>
  );
};

export default Component;

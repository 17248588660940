import dayjs from 'dayjs';
import { Md5 } from 'md5-typescript';
// import { elementsList, eleProperty, metalElements } from '@/config/const'

//替换分子式字符串
export const replaceFormulaStr = (str: any) => {
  str = String(str);
  str = str.replace(/0/g, "₀");
  str = str.replace(/1/g, "₁");
  str = str.replace(/2/g, "₂");
  str = str.replace(/3/g, "₃");
  str = str.replace(/4/g, "₄");
  str = str.replace(/5/g, "₅");
  str = str.replace(/6/g, "₆");
  str = str.replace(/7/g, "₇");
  str = str.replace(/8/g, "₈");
  str = str.replace(/9/g, "₉");
  return str;
}

// 深拷贝
export const deepClone = (obj: any) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  let result: any = Array.isArray(obj) ? [] : {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      result[key] = deepClone(obj[key]);
    }
  }
  return result;
}

export const randomId = () => {
  // 随机ID
  // return (Math.random() * 10000000).toString(16).substr(0, 4) + (new Date()).getTime() + Math.random().toString();
  return Md5.init((Math.random() * 10000000).toString(16).substr(0, 4) + (new Date()).getTime() + Math.random().toString());
}

export const radomGetArrOneData = (arr: any) => {
  // 从数组中随机取一个元素
  const index = radomInt(0, arr.length);
  // console.log(index);
  return arr[index];
}

//随机从数组中去随机或定长的数组
export const radomArr = (oarr: any, length: any = 3) => {
  // const count = radomInt(2, 4);
  const arr = deepClone(oarr);
  const list: any = []
  for (let n = 0; n < length; n++) {
    const r = radomInt(0, arr.length);
    const v = arr[r];
    list.push(v);
    arr.splice(r, 1)
  }
  return list;
}

export const shuffle = (arr: string[] | number[]) => {
  // 随机打乱数组
  for (let i = 0; i < arr.length; i++) {
    const iRand = parseInt(arr.length * Math.random() + '');
    const temp = arr[i];
    arr[i] = arr[iRand];
    arr[iRand] = temp;
  }
  return arr;
}

export const radomInt = (m: number, n: number) => {
  // 生成一定范围内的随机整数
  const num = Math.floor(Math.random() * (m - n) + n) + '';
  return parseInt(num);
};

export const radomFloat = (m: number, n: number) => {
  // 生成一定范围内的随机两位小数
  let num = Math.floor(Math.random() * (m - n) + n);
  num = parseInt(num + '') + parseFloat(Math.random() + '');
  num = Math.round(num * 100) / 100;
  return num;
};


export const radomDataArr = (max: number, min: number, arrLen: number) => {
  // 随机生成定长整数数组
  let arr = [];
  for (let n = 1; n <= arrLen; n++) {
    arr.push(radomInt(max, min));
  }
  return arr;
};
export const radomFloatDataArr = (max: number, min: number, arrLen: number) => {
  // 随机生成定长浮点数数组
  let arr = [];
  for (let n = 1; n <= arrLen; n++) {
    arr.push(radomFloat(max, min));
  }
  return arr;
};

export const getTwoNumber = (arr: number) => {
  // 保留两位小数
  return Math.round(arr * 100) / 100;
}

export const getQueryString: any = (name: any) => {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  const r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return null;
}


// 分子文件命名
const getName = (info: any) => {
  let f: any = '';

  if (info.hasOwnProperty('C')) {
    f += `C${replaceFormulaStr(info['C'].total)}`;
    delete info['C'];
  }
  if (info.hasOwnProperty('H')) {
    f += `H${replaceFormulaStr(info['H'].total)}`;
    delete info['H'];
  }

  Object.keys(info).map((key: any) => {
    f += `${key}${info[key].total === 1 ? '' : replaceFormulaStr(info[key].total)}`
  })
  return f
}

export const getJobName = (id: any) => {
  const arr = id.split('-');
  return '计算任务-' + arr[0];
}



//数组对象并集 key-比较字段
export const arrObjUnion = (arr1: any, arr2: any, key = 'id') => {
  const arr3 = arr1.concat(arr2);
  let result: any = [];
  let obj: any = [];
  result = arr3.reduce((prev: any, cur: any) => {
    obj[cur[key]] ? '' : obj[cur[key]] = true && prev.push(cur);
    return prev;
  }, []);
  return result
}

//简单数组数组并集
export const arrUnion = (arr1: any, arr2: any) => {
  return arr1.concat(arr2.filter((v: any) => !arr1.includes(v)))
}

//数组对象取交集
export const arrObjIntersection = (arr1: any, arr2: any) => {
  return arr2.filter((v: any) => {
    return arr1.some((n: any) => JSON.stringify(n) === JSON.stringify(v))
  })
}

//生成随机日期或时间
export const radomDate=(year:any=2024,dateFormat:any='YYYY-MM-DD')=>{
  const day=radomInt(1,Number(dayjs().date()));
  const month=radomInt(1,Number(dayjs().month()));
  const hour=radomInt(0,23);
  const minute=radomInt(0,59);
  const second=radomInt(0,59);
  return dayjs(`${year}-${month}-${day} ${hour}:${minute}:${second}`).format(dateFormat);
}



import React, { memo, CSSProperties, useCallback } from "react";
import {
  Handle,
  Position,
  NodeProps,
  Connection,
  Edge,
  useOnViewportChange,
  Viewport,
  Background,
} from "@xyflow/react";

import type { Reactor2 } from ".";

const targetHandleStyle: CSSProperties = { background: "#555", top: 110 };

const onConnect = (params: Connection | Edge) =>
  console.log("handle onConnect", params);

function Reactor2({ data, isConnectable }: NodeProps<Reactor2>) {
  const onStart = useCallback(
    (viewport: Viewport) => console.log("onStart", viewport),
    []
  );
  const onChange = useCallback(
    (viewport: Viewport) => console.log("onChange", viewport),
    []
  );
  const onEnd = useCallback(
    (viewport: Viewport) => console.log("onEnd", viewport),
    []
  );

  useOnViewportChange({
    onStart,
    onChange,
    onEnd,
  });

  return (
    <div
      style={{
        width: "280px",
        height: "140px",
        backgroundImage: "url(/reactor2.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          color: "white",
        }}
      >
        <strong>反应器 2</strong>
      </div>
      <Handle
        type="target"
        position={Position.Left}
        style={targetHandleStyle}
        onConnect={onConnect}
      />
    </div>
  );
}

export default memo(Reactor2);

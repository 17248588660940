import Nav from "./Nav";
import { themes, themsField } from "@/config/config";
import { Select, Row, Col, Space } from "antd";
import UserPanel from "./UserPanel";
import { SearchOutlined } from "@ant-design/icons";

const Component = (props: any) => {
  const { curTheme, setCurTheme } = props;
  const changeTheme = (e: any) => {
    setCurTheme(e);
    localStorage.setItem(themsField, e);
  };

  return (
    <div className="pageTopBar">
      <Nav />
      <div className="pageTopRight">
        <Space>
          {/* <Select onChange={changeTheme} value={curTheme}>
            {themes.map((item: any) => {
              return (
                <Select.Option value={item.code} key={item.code}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select> */}
          <div className="topSearchBar" style={{marginRight:'3rem'}}>
            <input type="text" className="topSearch" placeholder="输入项目名称" />
            <button className="topSubmit">
              <SearchOutlined style={{ fontSize: "1.6rem" }} />
            </button>
          </div>
          <UserPanel />
        </Space>
      </div>
    </div>
  );
};

export default Component;

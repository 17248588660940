import React, { useState, useEffect } from 'react';
import PageTitle from '@/Components/PageTitle';
import { message, Popconfirm, Space } from 'antd';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import { simpleSource } from '@/config/const'

const Component = (props: any) => {
  const [source,setSource]=useState<any>(simpleSource);
  const history = useNavigate();
  const del_confirm: any = (e: any) => {
    console.log(e);
    message.success("删除成功");
  };
  return (
    <div className="indexShow">
      <div className="indexShowMain">
        <PageTitle
          title={`任务列表：（${15}）`}
        // extra={
        //   <Space>

        //     <div className="button new" style={{ marginLeft: '2rem' }}>+ 新建任务</div>
        //   </Space>
        // }
        />
        <div className='indexTable mt2'>
      <table>
        <thead>
          <tr>
            <th>项目名称</th>
            <th>项目描述</th>
            <th>任务状态</th>
            <th>流程</th>
            <th>创建日期</th>
            <th>创建人</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          {source?source.map((item: any, index: any) => {
            return <tr key={index}>
              <td className='tabelImg'
              onClick={() => history(`/projects/view?k=${item.id}`)}
              >
                {/* <div>
                  <div className='itemPic'><div className='itemPicInner'><img src={item.pic} alt="" /></div></div>
                </div> */}
                <div className='itemName'>{item.name}</div>
              </td>
              <td className='itemDesc'>{item.desc}</td>
              <td className='itemFlowTotal'>进行中</td>
              <td className='itemTaskTotal'>流程一</td>
              <td>{dayjs(item.date).format('YYYY-MM-DD')}</td>
              <td>张XX</td>
              <td className='item_action_td'>
                <div className='item_action_c'>
                  <div className="item_action">
                    <div className="sp_icon sp_icon_view" title="查看"
                      onClick={() => history(`/flowview?k=${item.id}&f=2`)}
                    />
                    <div className="sp_icon sp_icon_edit" title="修改"
                      // onClick={() => history(`/create?k=${item.id}`)} 
                    />
                    <Popconfirm
                      title="确定删除该项目？"
                      description="删除后不可恢复！"
                      onConfirm={del_confirm}
                      // onCancel={cancel}
                      okText="是"
                      cancelText="否"
                    >
                      <div className="sp_icon sp_icon_delete" title="删除" />
                    </Popconfirm>
                  </div>
                </div>

              </td>
            </tr>
          }):''}
        </tbody>
      </table>

    </div>
      </div>
    </div>
  );
};


export default Component;
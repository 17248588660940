import axios from 'axios';

const apiRoot=window.origin==='http://localhost:8000'?'http://localhost:3030/api':`${window.origin}/api`


export interface LoginParamsType {
  username: string;
  password: string;
}

// export async function physicalProps(data: any) {
//   return await fetch(`${apiRoot}/zcphysicalprop?data=${JSON.stringify(data)}`, {
//     // {"op":"basicinfo","CAS":"7664-41-7"}
//     method: 'POST',
//     // headers: {
//     //   'content-type': 'application/json',
//     // },
//   });
// }

// export async function reactionCal(data: any, method: any) {
//   return await fetch(`${reactionRoot}/reactor?data=${JSON.stringify(data)}`, {
//     // {"op":"basicinfo","CAS":"7664-41-7"}
//     method,
//     // headers: {
//     //   'content-type': 'application/json',
//     // },
//   });
// }


export async function login(data: LoginParamsType) {
  const formBody = new URLSearchParams();
  formBody.append('username', data.username);
  formBody.append('password', data.password);
  
  return await fetch(`${apiRoot}/auth/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formBody,
  });
}
export async function getCurrentUser(token: string) {
  return await fetch(`${apiRoot}/users/me`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
}
// export async function createJob(data: any) {
//   return await axios({
//     url: `${apiRoot}/jobs`,
//     method: 'post',
//     data,
//     headers: {
//       'Content-Type':
//         'multipart/form-data;boundary=----WebKitFormBoundaryVCFSAonTuDbVCoAN',
//     },
//   });
// }

// export async function listJobs(params: any) {
//   return await axios({ url: `${apiRoot}/jobs`, method: 'GET', params });
// }

// export async function listPapers(params: any) {
//   return await axios({ url: `${apiRoot}/papers`, method: 'GET', params });
// }

// export async function getResult(id: any) {
//   return await axios({
//     url: `${apiRoot}/jobs/${id}`,
//     method: 'get',
//   });
// }

// export async function delJob(id: any) {
//   return await axios({
//     url: `${apiRoot}/jobs/${id}`,
//     method: 'delete',
//   });
// }

// export async function uploadFile(formData: FormData) {
// 	return await fetch(`${apiRoot}/files`, {
// 		method: 'POST',
// 		mode: 'cors',
// 		body: formData,
// 	});
// }
// export async function getMolInfo(formData: FormData) {
// 	return await fetch(`${apiRoot}/mol/details`, {
// 		method: 'POST',
// 		mode: 'cors',
// 		body: formData,
// 	});
// }
